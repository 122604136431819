<template>
  <div class="container">
    <div class="main-box">
      <p class="title">您有如下订单信息，请选择</p>
      <div class="business-box">
        <div
          :class="
            selectedBstp.includes('asg') ? 'business-item' : 'disable-item'
          "
          @click="toAsg"
        >
          <img class="item-img" src="../../../assets/images/asg_bg.jpg" />
          <div class="item-desc">
            <p class="item-title" @click="">科技服务ASG</p>
            <div class="order-box">
              <span v-for="(item, index) of asgOrderStatusCount" :key="index"
                >{{ getOrderLabel(item, asgOrderStatusCount)
                }}<el-divider
                  v-if="index !== asgOrderStatusCount.length - 1"
                  direction="vertical"
                ></el-divider
              ></span>
            </div>
            <div class="item-btn">
              <span class="btn" @click="">查看订单</span>
              <i class="el-icon-right"></i>
            </div>
          </div>
        </div>
        <div
          :class="
            selectedBstp.includes('bsg') ? 'business-item' : 'disable-item'
          "
          @click="toBsg"
        >
          <img class="item-img" src="../../../assets/images/bsg_bg.jpg" />
          <div class="item-desc">
            <p class="item-title">生物医药BSG</p>
            <div class="order-box">
              <span v-for="(item, index) of bsgOrderStatusCount" :key="index"
                >{{ getOrderLabel(item, bsgOrderStatusCount)
                }}<el-divider
                  v-if="index !== bsgOrderStatusCount.length - 1"
                  direction="vertical"
                ></el-divider
              ></span>
            </div>
            <div class="item-btn">
              <span class="btn" @click="">查看订单</span>
              <i class="el-icon-right"></i>
            </div>
          </div>
        </div>
        <div
          :class="
            selectedBstp.includes('csg') ? 'business-item' : 'disable-item'
          "
          @click="toCsg"
        >
          <img class="item-img" src="../../../assets/images/csg_bg.jpg" />
          <div class="item-desc">
            <p class="item-title" @click="">临床检测CSG</p>
            <div class="order-box">
              <span v-for="(item, index) of csgOrderStatusCount" :key="index"
                >{{ getOrderLabel(item, csgOrderStatusCount)
                }}<el-divider
                  v-if="index !== csgOrderStatusCount.length - 1"
                  direction="vertical"
                ></el-divider
              ></span>
            </div>
            <div class="item-btn">
              <span class="btn" @click="">查看订单</span>
              <i class="el-icon-right"></i>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mixinUser } from "@/mixins/index";
import { order } from "@/utils/dict.js";
import { getOrderStatusCount } from "@/api/order/index";
export default {
  name: "switchComponent",
  mixins: [mixinUser],
  data() {
    return {
      // 订单总数
      asgTotal: 10,
      bsgTotal: 10,
      csgTotal: 0,
      asg: [12, 13, 15], //asg业务类型列表
      bsg: [21, 22, 23], //bsg业务类型列表
      csg: [31, 32, 33], //csg业务类型列表
      selectedBstp: [], //用户能够选择哪几种业务类型
      busiType: order.busiType, //字典中的业务类型
      bstpList: order.bstpList, //字典中的业务类型列表
      statusList: order.status2, //订单状态列表
      asgOrderStatusCount: null, //ASG订单状态数量列表
      bsgOrderStatusCount: null, //BSG订单状态数量列表
      csgOrderStatusCount: null, //CSG订单状态数量列表
    };
  },
  methods: {
    toAsg() {
      if (!this.selectedBstp.includes("asg")) return;
      this.setUserBstp(this.asg, "asg");
      this.$router.push("/asg/order");
    },
    toBsg() {
      if (!this.selectedBstp.includes("bsg")) return;

      let first; //如果没有OTD，跳第一个BSG业务类型

      //获取接口返回的BSG业务联系
      const bsg = this.user.businessTypes.filter((item) =>
        this.bsg.includes(item.businessType)
      );
      for (const item of bsg) {
        if (item.businessType === Number(this.busiType.bsgOtd)) {
          //有OTD，跳OTD
          const bsgOtd = this.bstpList.find((item) => item.bstp === "bsgOtd");
          this.setUserBstp(this.bsg, bsgOtd.route);
          this.$router.push(bsgOtd.route);
          return;
        } else if (this.bsg.includes(item.businessType)) {
          //没有OTD，跳转第一个BSG
          first = `${item.businessType}`;
          break;
        }
      }

      if (first === this.busiType.bsgLtc) {
        const bsgLtc = this.bstpList.find((item) => item.bstp === "bsgLtc");
        this.setUserBstp(this.bsg, bsgLtc.route);
        this.$router.push(bsgLtc.route);
      }
    },
    toCsg() {
      if (!this.selectedBstp.includes("csg")) return;
      this.setUserBstp(this.csg, "csg");
      this.$router.push("/csg/order");
    },
    setUserBstp(bsTypes, userBstpSelected) {
      let userBstp = bsTypes.filter((item) => this.userBstp.includes(item));
      userBstp = userBstp.join(",");
      this.$utils.storage.setAttr("user", "userBstp", userBstp); //用户存在的多种业务类型
      this.$utils.storage.setAttr("user", "userBstpSelected", userBstpSelected); //用户选择的业务类型
    },
    handleGetUserData() {
      this.getUserData();
    },
    // 获取用户能够选择哪几种业务类型
    getSelectedBstp() {
      this.userBstp.map((item) => {
        if (this.asg.includes(item) && !this.selectedBstp.includes("asg")) {
          this.selectedBstp.push("asg");
        } else if (
          this.bsg.includes(item) &&
          !this.selectedBstp.includes("bsg")
        ) {
          this.selectedBstp.push("bsg");
        } else if (
          this.csg.includes(item) &&
          !this.selectedBstp.includes("csg")
        ) {
          this.selectedBstp.push("csg");
        }
      });
    },
    // 查询ASG订单状态数量
    getAsgOrderCount() {
      const params = {
        type: this.asg.join(","),
      };
      getOrderStatusCount(params).then((data) => {
        this.asgOrderStatusCount = data;
        this.asgOrderStatusCount.unshift({
          key: -1,
          count: 0,
        });
      });
    },
    // 查询BSG订单状态数量
    getBsgOrderCount() {
      const params = {
        type: this.bsg.join(","),
      };
      getOrderStatusCount(params).then((data) => {
        this.bsgOrderStatusCount = data;
        this.bsgOrderStatusCount.unshift({
          key: -1,
          count: 0,
        });
      });
    },
    // 查询CSG订单状态数量
    getCsgOrderCount() {
      const params = {
        type: this.csg.join(","),
      };
      getOrderStatusCount(params).then((data) => {
        this.csgOrderStatusCount = data;
        this.csgOrderStatusCount.unshift({
          key: -1,
          count: 0,
        });
      });
    },
    //获取每个状态的条数
    getOrderLabel({ key, count }, arr) {
      let name;
      key = Number(key);
      for (const item of this.statusList) {
        if (item.value == key) {
          name = item.name;
          break;
        } else if (item.value == -1) {
          name = item.name;
        }
      }
      if (key == -1) {
        //全部订单
        let total = 0;
        for (const item2 of arr) {
          total += item2.count;
        }
        return `${name} ${total}`;
      } else if (name) {
        //其它订单
        return `${name} ${count}`;
      }
    },
  },
  created() {
    this.handleGetUserData();
    this.getSelectedBstp();
    this.getAsgOrderCount();
    this.getBsgOrderCount();
    this.getCsgOrderCount();
  },
};
</script>

<style lang="scss" scoped>
.container {
  // height: 100%;

  .main-box {
    padding: 7%;
    width: 1200px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .title {
      font-size: 32px;
      color: 301303;
    }

    .business-box {
      width: 100%;
      margin-top: 84px;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      .business-item {
        width: 30%;
        max-width: 30%;
        height: 500px;
        // padding: 16px 16px;
        display: flex;
        flex-direction: column;

        .item-img {
          max-width: 100%;
          height: 360px;
          border-top-left-radius: 4px;
          border-top-right-radius: 4px;
          object-fit: cover;
        }

        .item-desc {
          padding: 24px 24px 50px;
          height: 140px;
          display: flex;
          flex-direction: column;
          background: #fff;
          border-bottom-left-radius: 4px;
          border-bottom-right-radius: 4px;
          font-size: 14px;
          position: relative;
          .item-title {
            font-size: 24px;
            font-weight: 600;
            line-height: 32px;
          }
          .order-box {
            margin-top: 40px;
            line-height: 22px;
            display: flex;
            flex-wrap: wrap;
            color: #909399;
          }
          .item-btn {
            font-size: 16px;
            color: #3399ff;
            width: 100%;
            display: flex;
            position: absolute;
            bottom: 20px;
            left: 20px;
            .btn {
              margin-right: 16px;
            }
          }
        }
      }

      // 鼠标移至显示阴影效果
      .business-item:hover {
        cursor: pointer;
        box-shadow: #c0c4cc 0px 6px 6px;
        transition: box-shadow 0.3s, border-color 0.3s;
      }

      .disable-item {
        width: 30%;
        max-width: 30%;
        height: 500px;
        opacity: 0.2;
        display: flex;
        flex-direction: column;

        .item-img {
          max-width: 100%;
          height: 360px;
          border-top-left-radius: 4px;
          border-top-right-radius: 4px;
          object-fit: cover;
        }

        .item-desc {
          padding: 24px 24px 50px;
          height: 140px;
          display: flex;
          flex-direction: column;
          background: #fff;
          border-bottom-left-radius: 4px;
          border-bottom-right-radius: 4px;
          font-size: 14px;
          position: relative;
          .item-title {
            font-size: 24px;
            font-weight: 600;
            line-height: 32px;
          }
          .order-box {
            margin-top: 40px;
            line-height: 22px;
            display: flex;
            flex-wrap: wrap;
            color: #909399;
          }
          .item-btn {
            font-size: 16px;
            color: #3399ff;
            width: 100%;
            display: flex;
            position: absolute;
            bottom: 20px;
            left: 20px;
            .btn {
              margin-right: 16px;
            }
          }
        }
      }
    }
  }
}
</style>
